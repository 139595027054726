<template>
  <Sidebar class="p-4 justify-content-between overview-sidebar">
    <div class="sidebar-top">
      <OrderViewList v-if="overview === 'order'" :views="viewsList" :title="'Views'" />
      <CustomerViewList v-else :views="viewsList" :title="'Views'" />
      <Filters
        v-if="!hideFilters"
        ref="filter"
        :title="'Filter'"
        :overview="overview"
        @filter="onFilter"
        @reset="onFilter"
      />
      <StoreFilter v-if="!hideStores" :title="'Stores'" @filterStores="filterStores" />
      <TagFilter
        v-if="!hideTags"
        :title="'Tags'"
        :overview="overview"
        @filterTags="filterTags"
      />
      <StatusFilter
        v-if="!hideStatus"
        :title="'Status'"
        @filterStatus="filterStatus"
      />
      <slot name="sidebar"></slot>
    </div>
    <slot class="flex-fill" />
    <div
      class="pb-lg-0 pb-sm-9"
    >
      <router-link
        v-if="!hideViews"
        :to="{ name: 'order.view.create' }"
        class="btn btn-sm btn-block btn-outline-secondary mb-4"
      >
        Add view
      </router-link>
    </div>
  </Sidebar>
</template>

<script>
import jsonAPI from "@/api";
import Sidebar from "@/components/Sidebar.vue";
import OrderViewList from "@/components/Order/OrderViewList.vue";
import CustomerViewList from "@/components/Order/CustomerViewList.vue";
import Filters from "@/components/Order/Filters.vue";
import TagFilter from "@/components/Order/TagFilter.vue";
import StoreFilter from "@/components/Order/StoreFilter.vue";
import StatusFilter from "@/components/Order/StatusFilter.vue";

export default {
  name: "OverviewSidebar",
  components: {
    Sidebar,
    OrderViewList,
    CustomerViewList,
    Filters,
    TagFilter,
    StoreFilter,
    StatusFilter,
  },
  props: {
    overview: {
      type: String,
      required: true,
      default: "" /*  should be: order or customer  */,
    },
    hideViews: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideStores: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTags: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideStatus: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    activeFilter: "",
    viewsList: [],
  }),
  beforeMount() {
    if (!this.hideViews) {
      jsonAPI.get("/configuration/view").then((response) => {
        const { data } = response.data;
        if(this.overview === 'customer') {
          var view_data = data.filter(dt => {
            if(dt.data.view_type === 'customer') {
              return dt;
            }
          });
        } else {
          view_data = data.filter(dt => {
            // to handle existing views without the view_type attribute
            if(dt.data.view_type !== 'customer') {
              return dt;
            }
          });
        }
        this.viewsList = view_data;
      });
    }
  },
  methods: {
    onFilter(segment) {
      this.$emit("filter", segment);
    },
    filterCategory(category_name) {
      this.$emit("filter", category_name);
    },
    reload() {
      this.$refs.filter.reload();
    },
    reset() {
      this.$refs.filter.reset();
    },
    filterTags(tag_slug) {
      this.$emit("filterTags", tag_slug);
    },
    filterStores(store_id) {
      this.$emit("filterStores", store_id);
    },
    filterStatus(status_key) {
      this.$emit("filterStatus", status_key);
    }
  },
};
</script>

<style scoped>
.overview-sidebar {
  position: fixed;
}
</style>
