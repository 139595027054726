<template>
  <div class="prompt" :class="{ 'is-visible': active }">
    <div class="content" role="dialog">
      <div class="header border-bottom">
        <slot name="header">
          <h3>{{ title }}</h3>
        </slot>
        <span class="sidebar-panel__close js-cd-close" @click="close"
          >Close</span
        >
      </div>
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Prompt",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Default title",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
