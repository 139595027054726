










import Vue from "vue";
import ChevronRight from "@/components/Svg/ChevronRight.vue";
import ChevronLeft from "@/components/Svg/ChevronLeft.vue";

export default Vue.extend({
  name: "Sidebar",
  components: {
    ChevronRight,
    ChevronLeft,
  },
  data() {
    return {
      isOpen: false,
    };
  },
});
