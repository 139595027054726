<template>
  <div class="table-responsive">
    <table
      cellpadding="0"
      cellspacing="0"
      class="table text-sm py-3 px-3 mb-0 bg-white w-100"
    >
      <thead>
        <data-table-header
          v-for="header in headers"
          :key="header.id"
          :header="header"
          :active="activeColumnSortKey === header.key"
          @sort="sort"
        />
      </thead>
      <tbody>
        <DataTableRow
          v-for="(item, itemIndex) in rows"
          :key="`item-${itemIndex}`"
          :class="{'prospect' : item.type=='prospect'}"
        >
          <td
            v-for="(header, headerIndex) in headers"
            :key="`header-${headerIndex}`"
            class="text-sm py-4 px-5 whitespace-no-wrap"
            style="display: table-cell;"
          >
            <slot :name="header.key" :item="item">
              {{ item[header.key] }}
            </slot>
          </td>
        </DataTableRow>
      </tbody>
    </table>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import DataTableHeader from "./DataTableHeader";
import DataTableRow from "./DataTableRow";

export default {
  name: "DataTable",
  components: {
    DataTableHeader,
    DataTableRow,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    sortUrl: {
      type: String,
      required: false,
    },
    method: {
     type: String,
     required: false,
     default: "put",
    },
    data: {
      type: [Array, Object],
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      rows: null,
      activeColumnSortKey: "",
    };
  },
  watch: {
    data: function(newVal) {
      this.rows = newVal;
    },
  },
  created() {
    this.rows = this.data;
  },
  mounted() {
    if (this.$route.query.sort) {
      this.activeColumnSortKey = this.$route.query.sort;
    }
  },
  methods: {
    sort(sort) {
      this.direction = this.toggleSort(this.direction);
      this.$nextTick(function() {
        this.activeColumnSortKey = sort.sortkey;
      });
      this.$emit("sort", {
        sortKey: sort.sortkey,
        direction: sort.direction,
      });
      let queryStr =
        this.sortUrl +
        "?page=1&sort=" +
        sort.sortkey +
        "&dir=" +
        this.direction;
      this.refreshData(queryStr);
      this.$router
        .replace({ query: { sort: sort.sortkey, dir: this.direction } })
        .catch((err) => {
          err;
        });
    },
    toggleSort(v) {
      return v === "desc" ? "asc" : "desc";
    },
    refreshData: function(queryStr) {
      const config = {
        method: this.method,
        url: queryStr
      }
      jsonAPI(config)  
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
/* tbody td:nth-child(2) {
    white-space: nowrap
} */
tbody tr td {
  word-wrap: break-word;
}
.prospect {
  background: rgba(137, 243, 129, 0.1) !important;
}
</style>
