<template>
    <Collapse :title="title">
        <div v-if="statusList.length === 0">
        <p class="ml-2 text-muted">
            No statuses available.
        </p>
        </div>
        <div v-else>
        <form action="">
            <ButtonFilter
                v-for="status in statusList"
                :key="status.key"
                :option="status"
                :current-option="currentOption"
                @click="handleClick(status.key)"
            />
        </form>
        <button
            class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
            @click="reset"
        >
            Reset filters
        </button>
        </div>
    </Collapse>
</template>

<script>
import ButtonFilter from "@/components/Order/ButtonFilter.vue";
import Collapse from "@/components/UI/Collapse.vue";
import { mapGetters } from "vuex";

export default {
    name: "TagFilter",
    components: {
        ButtonFilter,
        Collapse,
    },
    props: {
        title: {
            type: String,
            required: true,
            default: "",
        },
    },
    data() {
        return {
            currentOption: "",
            expandCollapse: false,
            active: false,
            statusList: [],
        };
    },
    computed: {
        ...mapGetters(["getStatusList"]),
    },
    async beforeMount() {      
        await this.$store.dispatch("GET_STATUS_LIST");
        this.statusList = this.getStatusList;
        this.renameLabelKey();
    },
    methods: {
        renameLabelKey() {
            this.statusList = this.statusList.map(function(status) {
                status['name'] = status['label']; // Assign new key
                delete status['label']; // Delete old key
                return status;
            });
        },
        reset() {
            this.currentOption = "";
            this.$emit("filterStatus", "");
        },
        handleClick(value) {
            this.currentOption = value;
            const status = this.statusList.filter((status) => status.key === value);
            const status_key = status[0].key;
            this.$emit("filterStatus", status_key);
        },
    }
}
</script>