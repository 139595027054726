<template>
  <div
    class="btn my-1 p-0 d-flex text-left align-items-center justify-content-between"
    :class="{ active }"
    @click="handleClick(option.id)"
  >
    {{ option.name }}
    <input type="radio" name="filter" :checked="option.id === currentOption" />
  </div>
</template>

<script>
export default {
  name: "ButtonFilter",
  props: {
    option: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    currentOption: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    active: false,
  }),
  methods: {
    handleClick(filterId) {
      this.$emit("click", filterId);
    },
  },
};
</script>
