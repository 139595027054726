<template>
  <StatusBadge
    v-if="getName"
    :style="[getStyle]"
    class="d-inline border rounded-pill m-1 px-4 py-1"
  >
    {{ getName }}
    <span v-if="url" @click="removeTag(getName)" class="pl-1">
      <Close />
    </span>
  </StatusBadge>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import Close from "@/components/Svg/Close.vue";

export default {
  name: "Tag",
  components: { StatusBadge, Close },
  props: {
    slug: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    tagmodel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    color: {
      type: String,
      default: "#fff",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tag: {
        slug: "",
        name: "",
        color: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getTags"]),
    getStyle() {
      const color = this.getColor();
      return {
        backgroundColor: color,
        color: this.getTagTextColor(),
      };
    },
    getName() {
      return this.tag ? this.tag.name : this.name;
    },
  },
  async beforeMount() {
    let tags = this.getTags;
    this.tag = tags.find((element) => element.slug === this.slug);
    if (!this.tag) {
      await this.$store.dispatch("GET_TAGS");
      let tags = this.getTags;
      this.tag = tags.find((element) => element.slug === this.slug);
    }
  },
  methods: {
    getColor() {
      return this.tag ? this.tag.color : this.color;
    },
    removeTag(tag_name) {
      const name_slug = tag_name
        .split(" ")
        .join("_")
        .toLowerCase();
      const tagmodel = Object.assign({}, this.tagmodel);
      tagmodel.tags = tagmodel.tags.filter((rm_tag) => {
        return rm_tag !== name_slug;
      });

      jsonAPI
        .put("/" + this.url + "/" + tagmodel.id, tagmodel)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: `${this.url} updated`,
            type: "success",
          });
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Update error",
            type: "error",
          });
        });

      this.$emit("removetag", tagmodel);
    },
    getTagTextColor() {
      var backgroundcolor = this.getColor();
      var r, g, b, hsp;
      if (backgroundcolor.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        backgroundcolor = backgroundcolor.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = backgroundcolor[1];
        g = backgroundcolor[2];
        b = backgroundcolor[3];
      } else {
        // If hex --> Convert it to RGB
        backgroundcolor = +(
          "0x" +
          backgroundcolor
            .slice(1)
            .replace(backgroundcolor.length < 5 && /./g, "$&$&")
        );

        r = backgroundcolor >> 16;
        g = (backgroundcolor >> 8) & 255;
        b = backgroundcolor & 255;
      }
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {
        return "#000";
      } else {
        return "#fff";
      }
    },
  },
};
</script>

<style scoped></style>
