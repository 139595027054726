
















import Vue from "vue";

export default Vue.extend({
  name: "ChevronRight",
  props: {
    height: {
      type: String,
      default: "55",
    },
    color: {
      type: String,
      default: "#ffffff",
    },
  },
  computed: {
    pathStyle() {
      return { fill: this.color };
    },
  },
});
