<template>
    <Collapse
        :title="title"
    >
        <div v-if="tags.length === 0">
        <p class="ml-2 text-muted">
            No tags available.
        </p>
        </div>
        <div v-else>
        <form action="">
            <ButtonFilter
                v-for="option in tags"
                :key="option.id"
                :option="option"
                :current-option="currentOption"
                @click="handleClick"
            />
        </form>
        <button
            class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
            @click="reset"
        >
            Reset filters
        </button>
        </div>
    </Collapse>
</template>

<script>
import ButtonFilter from "@/components/Order/ButtonFilter.vue";
import Collapse from "@/components/UI/Collapse.vue";
import { mapGetters } from "vuex";

export default {
    name: "TagFilter",
    components: {
        ButtonFilter,
        Collapse,
    },
    props: {
        title: {
            type: String,
            required: true,
            default: "",
        },
        overview: {
            type: String,
            required: true,
            default: "", 
        },
    },
    data() {
        return {
            currentOption: "",
            expandCollapse: false,
            active: false,
            tags: [],
        };
    },
    computed: {
        ...mapGetters(["getTags"]),
    },
    async beforeMount() {      
        await this.$store.dispatch("GET_TAGS");
        this.tags = this.getTags;
    },
    methods: {
        reset() {
            this.currentOption = "";
            this.$emit("filterTags", "");
        },
        handleClick(value) {
            this.currentOption = value;
            const tag = this.tags.filter((tag) => tag.id === value);
            const tag_slug = tag[0].slug;
            this.$emit("filterTags", tag_slug);
        },
    }
}
</script>