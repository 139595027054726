<template>
    <div class="modal-body">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="col-md-12 d-flex px-0">
              <div class="form-group col-md-6 pl-0 pr-2 required" :class="{ 'is-invalid': errors.has('customer_id') }">
                <label for="customer_id" class="control-label text-capitalize">Customer number</label>
                <input id="customer_id" v-model="customer.customer_id" v-validate="{ required: true }" type="text" name="customer_id" class="form-control" />
                <span v-show="errors.has('customer_id')" class="invalid-feedback">{{ errors.first("customer_id") }}</span>
              </div>
              <div class="form-group col-md-6 pr-0 pl-2">
                <label for="type" class="control-label text-capitalize"
                  >Customer Type</label
                >
                <multiselect
                  id="type"
                  v-model="customer.type"
                  name="type"
                  :options="statusList"
                  track-by="key"
                  label="label"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="changeStatus"
                />
              </div>
            </div>
            <div class="col-md-12 d-flex px-0">
              <div class="form-group col-md-6 pl-0 pr-2" :class="{ 'is-invalid': errors.has('first_name') }">
                <label for="first_name" class="control-label text-capitalize">First name</label>
                <input id="first_name" v-model="customer.first_name" v-validate="{ required: false }" type="text" name="first_name" class="form-control" />
                <span v-show="errors.has('first_name')" class="invalid-feedback">{{ errors.first("first_name") }}</span>
              </div>
              <div class="form-group col-md-6 pr-0 pl-2" :class="{ 'is-invalid': errors.has('last_name') }">
                <label for="last_name" class="control-label text-capitalize">Last name</label>
                <input id="last_name" v-model="customer.last_name" v-validate="{ required: false }" type="text"
                  name="last_name" class="form-control" />
                <span v-show="errors.has('last_name')" class="invalid-feedback">{{ errors.first("last_name") }}</span>
              </div>
            </div>
            <div class="col-md-12 d-flex px-0 mb-2">
              <div class="form-group required col-md-6 pl-0 pr-2" :class="{ 'is-invalid': errors.has('email') }">
                <label for="email" class="control-label text-capitalize">Email</label>
                <input id="email" v-model="customer.email" v-validate="{ required: true }" type="text" name="email"
                  class="form-control" />
                <span v-show="errors.has('email')" class="invalid-feedback">{{ errors.first("email") }}</span>
              </div>
              <div class="form-group col-md-6 pr-0 pl-2" :class="{ 'is-invalid': errors.has('telephone') }">
                <label for="telephone" class="control-label text-capitalize">Phone Number</label> 
                <TelephoneInput
                 @valueChange="setTelephoneValue" 
                 @countryChange="setCountryValue"
                 :telephone="customer.telephone"
                 id="telephone"
                 name="telephone"
                />
                <span v-show="errors.has('telephone')" class="invalid-feedback">{{ errors.first("telephone") }}</span>
              </div>
            </div>
            <div class="col-md-12 d-flex px-0 mb-2">
              <div class="form-group col-md-6 pr-0 pl-2" :class="{ 'is-invalid': errors.has('country') }">
                <label for="country" class="control-label text-capitalize">Country</label> 
                <multiselect
                  id="country"
                  v-model="currentCountry"
                  v-validate="{ required: true }"
                  name="country"
                  :options="countries"
                  label="name"
                  track-by="code"
                  :show-labels="false"
                  @select="changeCountry"
                />
              </div>
            </div>
            <div class="form-group col-md-12 rounded py-3 border">
              <editable-address title="Shipping address" simple :address="customer.shipping_address"
                :default-country="getDefaultCountry" />
              <span v-show="errors.has('shipping_address')" class="invalid-feedback">{{ errors.first("shipping_address")
              }}</span>
            </div>
            <div class="form-group col-md-12 rounded py-3 border">
              <editable-address title="Billing address" simple :address="customer.billing_address" :default-country="getDefaultCountry" />
            </div>
            <ButtonLoading :class="'btn-secondary float-right'" :loading="loading" :title="'Save'" v-on:click.native="submitForm" />
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import { postToApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import EditableAddress from "@/components/UI/EditableAddress.vue";
import Multiselect from "vue-multiselect";
import countries from "@/helpers/countries";
import { mapGetters } from "vuex";
import customerStatus from "@/helpers/customerStatus";
import TelephoneInput from "@/components/Form/TelephoneInput.vue";

export default {
  components: {
    ButtonLoading,
    EditableAddress,
    Multiselect,
    TelephoneInput,
  },
  data: () => {
    return {
      layout: "Default",
      loading: false,
      hasError: false,
      customer: {
        organization_id: null,
        customer_id: null,
        type: {
          key: "prospect",
          label: "Prospect"
        },
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        country: "",
        shipping_address: {
          first_name: "",
          last_name: "",
          company: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          email: "",
          telephone: "",
        },
        billing_address: {
          first_name: "",
          last_name: "",
          company: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          email: "",
          telephone: "",
        },
      },
      countries: countries,
      currentCountry: {
        name: '',
        code: '',
      },
      statusList: customerStatus,
    };
  },
  computed: {
    ...mapGetters(['getOrganization']),
    getDefaultCountry() {
      if(this.getOrganization && this.getOrganization.country_obj) {
        return this.getOrganization.country_obj;
      } else {
        return {
          code: "",
          name: "",
        };
      }
    },
  },
  methods: {
    async submitForm() {
      this.loading = true;

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const data = utilities.cleanData(this.customer);
          data.organization_id = this.getOrganization.id;
          if (typeof data.type === "object") {
            data.type = data.type.key;
          }
          await postToApi("/customer", data).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
            this.hasError = true;
            window.Bus.$emit("flash-message", {
              text: "error main",
              type: "error",
            });
          });
          if (!this.hasError) {
            this.$emit("close");
          }
        }
        this.loading = false;
        this.hasError = false;
      });
    },
    changeStatus(event) {
      this.customer.type = event.key;
    },
    setTelephoneValue(val) {
      this.customer.telephone = val;
    },
    changeCountry(event) {
      this.customer.country = event.code;
    },
    setCountryValue(val) {
      this.currentCountry.code = val.iso2;
      this.currentCountry.name = val.name;
      this.customer.country = val.iso2;
    },
  },
};
</script>
