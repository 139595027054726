<template>
  <div class="api-loader-wrapper">
    <Spinner v-if="isLoading" />
    <slot v-else />
  </div>
</template>

<script>
import Spinner from "@/components/UI/Spinner.vue";
export default {
  name: "ApiLoader",
  components: {
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.api-loader-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
