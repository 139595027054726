<template>
  <div>
    <div class="modal-body">
      <div class="form-group">
        <label for="title">Select type</label>
        <select class="form-control" v-model="type" required>
          <option value="">Select document type</option>
          <option v-for="ty in types" :key="ty">{{ ty }}</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary push" @click="exportData">
        Download
      </button>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import { buildPaginationString } from "@/helpers/apiConnection";

export default {
  name: "RerunAutomation",
  props: {
    conditionData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    viewId: {
      type: String,
      required: false,
      default: "",
    },
    overview: {
      type: String,
      required: true,
    },
    customerType: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      types: ["xls", "xlsx", "csv", "pdf"],
      type: "",
    };
  },
  methods: {
    close() {
      this.$parent.$emit("close");
    },
    exportData() {
      if(this.overview === 'order') {
        this.exportOrders();
      } else {
        this.exportCustomers();
      }
    },
    exportCustomers() {
      if (this.type) {
        const page = 1;
        const customer_type = this.customerType;
        const sortKey = this.$route.query.sort ?? "created_at";
        const sortDirection = this.$route.query.dir ?? "desc";
        const query = buildPaginationString(sortKey, sortDirection, page, customer_type);
        var url = `/exports/customers/${this.type}?${query}`;
        jsonAPI({
          url: url,
          method: "PUT",
          data: this.conditionData,
          responseType: "blob",
        })
          .then((resp) => {
            if (resp) {
              var fileURL = window.URL.createObjectURL(
                new Blob([resp.data], { type: "application/octet-stream" })
              );
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", "file." + this.type);
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          })
          .catch(() => {
            window.Bus.$emit("flash-message", {
              text: "Error fetching results",
              type: "error",
            });
          });
      } else {
        window.Bus.$emit("flash-message", {
          text: "Please select valid type",
          type: "error",
        });
      }
    },
    exportOrders() {
      if (this.type) {
        const page = 1; // from the backend, will download all orders based on the conditions
        const sortKey = this.$route.query.sort ?? "created_at";
        const sortDirection = this.$route.query.dir ?? "desc";
        const query = buildPaginationString(sortKey, sortDirection, page);
        var url = "";
        if (this.viewId) {
          url = `/exports/orders/view/${this.type}/${this.viewId}?${query}`;
        } else {
          url = `/exports/orders/${this.type}?${query}`;
        }
        jsonAPI({
          url: url,
          method: "PUT",
          data: this.conditionData,
          responseType: "blob",
        })
          .then((resp) => {
            if (resp) {
              var fileURL = window.URL.createObjectURL(
                new Blob([resp.data], { type: "application/octet-stream" })
              );
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", "file." + this.type);
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          })
          .catch(() => {
            window.Bus.$emit("flash-message", {
              text: "Error fetching results",
              type: "error",
            });
          });
      } else {
        window.Bus.$emit("flash-message", {
          text: "Please select valid type",
          type: "error",
        });
      }
    },
  },
};
</script>
