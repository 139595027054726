


























import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  name: "TextArea",
  components: {
    ValidationProvider
  },
  props: {
    id: {
      type: String,
      default: "",
      required: true,
    },
    type: {
      type: String,
      default: "",
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    rules: {
      type: String,
      default: "",
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    default: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value(val) {
      this.$emit("valueChange", this.id, val);
    },
    default(val) {
      if (!this.value) {
        this.value = val;
      }
    },
  },
});
