<template>
  <div class="mb-4 border-primary border p-4 bg-blue-100">
    <prompt
      :active="isSaveSegmentVisible"
      @close="isSaveSegmentVisible = false"
      :title="'Save filter'"
    >
      <div slot="content">
        <div class="form-row p-4">
          <div
            class="form-group col-12 required"
            :class="{ 'is-invalid': errors.has('name') }"
          >
            <label for="name" class="control-label text-capitalize"
              >Filter name</label
            >
            <input
              id="name"
              v-model="segmentName"
              v-validate="{ required: true }"
              type="text"
              name="name"
              class="form-control"
              placeholder="VIP customers"
              autocomplete="off"
            />
            <span v-show="errors.has('name')" class="invalid-feedback">{{
              errors.first("name")
            }}</span>
          </div>
          <div class="btn btn-block btn-outline-secondary" @click="savefilter">
            Save
          </div>
        </div>
      </div>
    </prompt>
    <div class="d-flex justify-content-center">
      <button
        v-if="closed"
        class="btn btn-outline-primary btn-sm"
        @click="toggleDisplay"
      >
        Show filters
      </button>
    </div>
    <!-- Show Active Filters -->
    <div v-if="!closed && showCurrentFiltering">
      <div class="card border-primary shadow-sm">
        <div class="card-body">
          <p class="mb-1">
            Current filtering: {{ condition_name }}
            <button
              class="btn btn-outline-primary btn-sm float-right"
              @click="showCurrentFiltering=false"
            >
              Edit filter
            </button>
          </p>
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="media position-relative">
                <div class="css-e2avkx">
                  <span class="css-134uqom">
                    <CheckmarkCircle />
                  </span>
                </div>
                <ConditionFork class="mr-4" />
                <div class="media-body">
                  <p
                    class="h6 text-gray-700 font-weight-normal text-uppercase mb-0"
                  >
                    Condition: {{ conditions_group }}
                  </p>
                  <div v-for="(current, index) in conditions" :key="index">
                    <p class="h5">{{ current.field + ' ' + current.operator + ' '+ conditionValue(current) }}</p>
                    <hr v-if="index != Object.keys(conditions).length - 1">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!closed && !showCurrentFiltering">
      <ConditionBtn
        :show="conditionsEmpty"
        :show-line="false"
        @newCondition="addCondition"
      />
      <div v-if="hasMultipleConditions" class="card">
        <div class="card-body text-center">
          <p>Select how the conditions should be evaluated</p>
          <div class="d-flex justify-content-center mb-1">
            <button
              class="btn mr-3 pl-5 pr-5"
              :class="
                conditions_group === 'AND' ? 'btn-primary' : 'btn-outline-primary'
              "
              @click="conditions_group = 'AND'"
            >
              And
            </button>
            <button
              class="btn pl-5 pr-5"
              :class="
                conditions_group === 'OR' ? 'btn-primary' : 'btn-outline-primary'
              "
              @click="conditions_group = 'OR'"
            >
              Or
            </button>
          </div>
          <div class="d-flex justify-content-center">
            <div v-if="conditions_group === 'AND'">
              <p class="mb-0">
                All conditions should apply for this automation to run.
              </p>
            </div>
            <div v-else-if="conditions_group === 'OR'">
              <p class="mb-0">
                Any of these conditions will make the automation run.
              </p>
            </div>
            <div v-else>
              <p class="mb-0" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasMultipleConditions" class="css-12w0090" />
      <div v-for="(condition, index) in conditions" :key="index">
        <Condition
          :condition="condition"
          :index="index"
          :model="model"
          @deleteCondition="deleteCondition"
        />
        <ConditionBtn
          :show="index + 1 === Object.keys(conditions).length"
          class="add-more"
          @newCondition="addCondition"
        />
      </div>
      <div class="flex mb-2">
        <button class="btn btn-outline-danger btn-sm" @click="toggleDisplay">
          close
        </button>
        <button
          class="btn btn-primary btn-sm float-right"
          @click="applyFilters"
        >
          Apply filters
        </button>
        <button
          v-if="conditions.length > 0"
          class="btn btn-outline-primary btn-sm float-right mr-2"
          @click="openSaveFitler"
        >
          Save filters
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConditionBtn from "@/components/Automation/ConditionBtn";
import Condition from "@/components/Automation/Condition";
import Prompt from "@/components/UI/Prompt";
import { postToApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";
import ConditionFork from "@/components/Svg/ConditionFork.vue";
import CheckmarkCircle from "@/components/Svg/CheckmarkCircle.vue";

export default {
  name: "ConditionBuilder",
  components: {
    ConditionBtn,
    Condition,
    Prompt,
    ConditionFork,
    CheckmarkCircle,
  },
  props: {
    model: {
      type: String,
      default: "order",
    },
    conditionData: {
      type: Object
    }
  },
  data() {
    return {
      conditions: [
        {
          field: "total",
          type: "order",
          operator: "==",
          value: "",
        },
      ],
      conditions_group: "AND",
      condition_name: "",
      closed: true,
      isSaveSegmentVisible: false,
      segmentName: "",
      showCurrentFiltering: true,
    };
  },
  computed: {
    hasConditions() {
      return this.conditions.length > 0;
    },
    hasMultipleConditions() {
      return this.conditions.length > 1;
    },
    conditionsEmpty() {
      return this.conditions.length === 0;
    },
  },
  watch: {
    conditionData: function() {
      this.conditions = this.conditionData.conditions;
      this.conditions_group = this.conditionData.conditionGroup;
      this.condition_name = this.conditionData.conditionName;
      this.closed = false;
      this.showCurrentFiltering = true;
    }
  },
  methods: {
    addCondition() {
      this.conditions.push({
        field: "total",
        type: "order",
        operator: "==",
        value: "",
      });
    },
    deleteCondition(index) {
      this.$delete(this.conditions, index);
    },
    applyFilters() {
      const conditionData = {
        conditions: this.conditions,
        conditionGroup: this.conditions_group,
      };
      this.$emit("apply-filters", conditionData);
      this.closed = true;
    },
    openSaveFitler() {
      this.isSaveSegmentVisible = true;
    },
    savefilter() {
      this.loading = true;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const form = {
            name: this.segmentName,
            type: "order",
            conditions_group: this.conditions_group,
            conditions: this.conditions,
            organization_id: 1,
          };
          const data = utilities.cleanData(form);
          await postToApi("/segment", data).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
            this.hasError = true;
            window.Bus.$emit("flash-message", {
              text: "error main",
              type: "error",
            });
          });
          if (!this.hasError) {
            this.isSaveSegmentVisible = false;
            this.segmentName = "";
            this.$emit("segment-saved", []);
          }
        }
      });
    },
    toggleDisplay() {
      this.closed = !this.closed;
      this.showCurrentFiltering = !this.showCurrentFiltering;
    },
    // set the condition value as a decimal
    conditionValue(val) {
      if (
        (val.field === "total" || val.field === "price") &&
        val.operator !== "isInRange" &&
        val.operator !== "isNotInRange"
      ) {
        return parseFloat(Math.round(val.value) / 100).toFixed(2);
      }
      return val.value;
    },
  },
};
</script>

<style scoped>
.add-more {
  margin-top: -12px;
}
.prompt {
  width: 100%;
  position: absolute;
}
</style>
