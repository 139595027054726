<template>
    <Sidebar class="p-4 justify-content-between help-sidebar">
        <div class="content" role="dialog">
            <div class="header border-bottom pb-2 mb-4">
                <h4>Help</h4>
                <span class="sidebar-panel__close js-cd-close" @click="close">
                    Close
                </span>
            </div>
            <slot />
        </div>
    </Sidebar>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
    name: "HelpModal",
    components: {
        Sidebar,
    },
    data() {
        return {
            active: true
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
    }
}
</script>

<style>
.help-sidebar {
    right: 24px;
    position: absolute;
    z-index: 999;
    transition: 1s;
}

</style>