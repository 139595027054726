






import Vue from "vue";
import { VueTelInput } from 'vue-tel-input';

export default Vue.extend({
    name: "TelephoneInput",
    components: {
        VueTelInput,
    },
    props: {
        telephone: {
            type: String,
            default: "",
            required: false
        }
    },
    data() {
        return {
            phoneOptions: {
                mode: "international",
                // defaultCountry: "",
                disabledFormatting: true,
                placeholder: "Enter phone number",
                enabledCountryCode: false,
                autocomplete: "off",
                name: "telephone",
                inputOptions: {
                    showDialCode: false,
                    styleClasses: "form-control",
                },
                styleClasses: "input-group",
                validCharactersOnly: true,
            }
        };
    },
    watch: {
        telephone(val) {
            this.$emit("valueChange", val);
        },
    },
    methods: {
        countryChanged(val) {
            this.$emit("countryChange", val);
        },
    },
});
