<template>
  <Collapse
    :data-title="
      'Using filters you can view a subset of your ' + overview + 's.'
    "
    data-intro=""
    data-step="3"
    :title="title"
  >
    <div v-if="filters.length === 0">
      <p class="ml-2 text-muted">
        No custom filters available.
        <router-link
          :to="{ name: 'organization.segment.index' }"
          class="btn btn-sm btn-block btn-outline-secondary mb-4"
        >
          Add filters
        </router-link>
      </p>
    </div>
    <div v-else>
      <form action="">
        <ButtonFilter
          v-for="option in filters"
          :key="option.id"
          :option="option"
          :current-option="currentOption"
          @click="handleClick"
        />
      </form>
      <button
        class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
        @click="reset"
      >
        Reset filters
      </button>
    </div>
  </Collapse>
</template>

<script>
import jsonAPI from "@/api";
import ButtonFilter from "@/components/Order/ButtonFilter.vue";
import Collapse from "@/components/UI/Collapse.vue";
export default {
  name: "Filters",
  components: {
    ButtonFilter,
    Collapse,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    overview: {
      type: String,
      required: true,
      default: "", // Updates the intro elements to either order or customer
    },
  },
  data: () => ({
    currentOption: "",
    expandCollapse: false,
    active: false,
    filters: [],
  }),
  mounted() {
    this.loadSegments();
  },
  methods: {
    loadSegments() {
      jsonAPI.get("/segment").then((response) => {
        const { data } = response.data;
        const filteredFilters = data.filter(item => {
          if(item.type === this.overview){
            return item;
          }
        })
        this.filters = filteredFilters;
      });
    },
    handleClick(value) {
      this.currentOption = value;
      const segment = this.filters.filter((filter) => filter.id === value);
      this.$emit("filter", segment.pop());
    },
    reload() {
      this.loadSegments();
    },
    reset() {
      this.currentOption = "";
      this.$emit("filter", []);
    },
  },
};
</script>

<style scoped></style>
