<template>
    <InfoCircle v-tooltip="title" />
</template>

<script>
import InfoCircle from '@/components/Svg/InfoCircle.vue';

export default {
    name: "Tooltip",
    components: {
        InfoCircle
    },
    props: {
        title: {
            type: String,
            default: ""
        }
    }
    
}
</script>