<template>
    <Collapse :title="title">
        <div v-if="stores.length === 0">
        <p class="ml-2 text-muted">
            No stores available.
        </p>
        </div>
        <div v-else>
        <form action="">
            <ButtonFilter
                v-for="store in stores"
                :key="store.id"
                :option="store"
                :current-option="currentOption"
                @click="handleClick"
            />
        </form>
        <button
            class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
            @click="reset"
        >
            Reset filters
        </button>
        </div>
    </Collapse>
</template>

<script>
import ButtonFilter from "@/components/Order/ButtonFilter.vue";
import Collapse from "@/components/UI/Collapse.vue";
import jsonAPI from "@/api";

export default {
    name: "StoreFilter",
    components: {
        ButtonFilter,
        Collapse,
    },
    props: {
        title: {
            type: String,
            required: true,
            default: ""
        }
    },
    data() {
        return  {
            currentOption: "",
            expandCollapse: false,
            active: false,
            stores: []
        }
    },
    mounted() {
        this.getStores();
    },
    methods: {
        getStores() {
            jsonAPI.get("store").then((response) => {
                this.stores = response.data.data;
            });
        },
        reset() {
            this.currentOption = "";
            this.$emit("filterStores", "");
        },
        handleClick(value) {
            this.currentOption = value;
            const store = this.stores.filter((store) => store.id === value);
            const store_id = store[0].id;
            this.$emit("filterStores", store_id);
        },
    }
}
</script>