<template>
  <Collapse
    data-title="With views you're able to determine what columns with order data are shown in the filtered overview."
    data-intro=""
    data-step="1"
    :title="title"
  >
    <router-link
      v-for="view in views"
      :key="view.id"
      :to="{ name: 'order.view', params: { id: view.id } }"
      class="btn btn-sm btn-outline-secondary d-flex text-left align-items-center lift mb-2"
      exact
    >
      {{ view.data.name }}
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-arrow-right-short ml-auto"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
        />
      </svg>
    </router-link>
    <button
      v-if="views.length > 0"
      class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
      @click="$router.push({ name: 'order.index' })"
    >
      Select default view
    </button>
    <p v-if="views.length === 0" class="ml-2 text-muted">
      No custom views available.
      <router-link
        :to="{ name: 'order.view.create' }"
        class="btn btn-sm btn-block btn-outline-secondary mb-4"
      >
        Add views
      </router-link>
    </p>
  </Collapse>
</template>

<script>
import Collapse from "@/components/UI/Collapse.vue";

export default {
  name: "OrderViewList",
  components: {
    Collapse,
  },
  props: {
    views: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      expandCollapse: false,
    };
  },
};
</script>

<style scoped>
.btn-block.active svg {
  transform: rotate(180deg);
}
</style>
