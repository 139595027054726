<template>
    <div class="modal-body">
        <div class="row fadeInUp" role="main">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="card border-0 shadow-sm">
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <p>
                                    Messages will be sent to {{ message_count }} customers
                                </p>
                            </div>
                        </div>
                        <div class="form-row" v-if="!conditionData.conditions">
                            <div class="form-group col-12">
                                <p class="mb-0">
                                    Conditions:
                                </p>
                            </div>
                            <div
                                v-if="Object.keys(form.conditions).length === 0"
                                class="form-group col-12"
                            >
                                <div
                                    class="btn btn-block btn-outline-secondary"
                                    @click="addCondition"
                                >
                                    Add condition
                                </div>
                            </div>
                        </div>
                        <div v-if="form.conditions.length > 1">
                            <div class="text-center">
                                <p>Select how the conditions should be evaluated</p>
                                <div class="d-flex justify-content-center">
                                    <button
                                        class="btn mr-3"
                                        :class="
                                        form.conditions_group === 'AND'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                        "
                                        @click="form.conditions_group = 'AND'"
                                    >
                                        And
                                    </button>
                                    <button
                                        class="btn"
                                        :class="
                                        form.conditions_group === 'OR'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                        "
                                        @click="form.conditions_group = 'OR'"
                                    >
                                        Or
                                    </button>
                                </div>
                                <!-- add description when condition is selected -->
                                <br />
                                <div class="d-flex justify-content-center">
                                    <div v-if="form.conditions_group === 'AND'">
                                        <p>
                                        All conditions should apply for this automation to run.
                                        </p>
                                    </div>
                                    <div v-else-if="form.conditions_group === 'OR'">
                                        <p>Any of these conditions will make the automation run.</p>
                                    </div>
                                    <div v-else>
                                        <p />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(condition, index) in form.conditions" :key="index">
                            <condition
                                :condition="condition"
                                :index="index"
                                :simple="true"
                                :model="form.type"
                                @deleteCondition="deleteCondition"
                                class="mb-4"
                            />
                            <div
                                v-if="index + 1 == Object.keys(form.conditions).length"
                                class="btn btn-block btn-outline-secondary mt-4"
                                @click="addCondition"
                            >
                                Add another condition
                            </div>
                        </div>
                        <div class="form-row mt-2">
                            <TextArea 
                                :id="'message'"
                                :type="'input'"
                                :title="'Message'"
                                :rules="'required'"
                                :default="message"
                                @valueChange="setMessageValue"
                            />
                        </div>

                        <div class="form-row">
                            <div class="form-group col-12">
                                <ButtonLoading
                                    :class="'btn-secondary-soft'"
                                    :title="'Update count'"
                                    :loading="loading"
                                    v-on:click.native="calculateCustomerMessages"
                                />
                                <ButtonLoading
                                    :class="'btn-primary float-right'"
                                    :title="'Send SMS'"
                                    :loading="loading"
                                    v-on:click.native="sendMessage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextArea from "@/components/Form/TextArea.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import Condition from "@/components/Automation/Condition.vue";
import jsonAPI from "@/api";
import { mapGetters } from "vuex";

export default {
    name: "MessageCustomer",
    components: {
        TextArea,
        ButtonLoading,
        Condition,
    },
    props: {
        conditionData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        customerType: {
            type: String,
            default: "all"
        }
    }, 
    data() {
        return {
            layout: "Default",
            form: {
                type: "customer",
                conditions_group: "AND",
                conditions: [],
            },
            message: "",
            message_count: "",
            loading: false
        }
    },
    computed: {
        ...mapGetters(["getOrganization", "getUser"]),
    },
    async mounted() {
        this.calculateCustomerMessages();
    },
    methods: {
        calculateCustomerMessages() {
            this.loading = true;
            const messageConditions = {
                type: this.customerType,
                conditionGroup: this.conditionData.conditionGroup ? this.conditionData.conditionGroup : this.form.conditions_group,
                conditions: this.conditionData.conditions ? this.conditionData.conditions : this.form.conditions
            };
            jsonAPI
                .put("messages/calculate", messageConditions)
                .then((data) => {
                    this.message_count = data.data;
                    this.loading = false;
                })
                .catch(() => {
                    window.Bus.$emit("flash-message", {
                        text: "Error happened when calculating customer messages",
                        type: "error",
                    });
                })
        },
        deleteCondition(index) {
            this.$delete(this.form.conditions, index);
        },
        addCondition() {
            this.form.conditions.push({
                field: "",
                type: "",
                operator: "",
                value: "",
            });
        },
        setMessageValue(field, value) {
            this.$set(this, field, value);
        },
        sendMessage() {
            this.loading = true;
            const userName = (this.getUser ?? {}).name;
            const orgId = (this.getOrganization ?? {}).id;
            const messageDetails = {
                organization_id: orgId,
                user: userName,
                message: this.message,
                type: "SMS",
                direction: "OUTBOUND",
                customer_type: this.customerType,
                conditionGroup: this.conditionData.conditionGroup ? this.conditionData.conditionGroup : this.form.conditions_group,
                conditions: this.conditionData.conditions ? this.conditionData.conditions : this.form.conditions
            }
            jsonAPI
                .post("messages/customersegments", messageDetails)
                .then(() => {
                    window.Bus.$emit("flash-message", {
                        text: "Messages sent",
                        type: "success",
                    });
                    this.loading = false;
                    this.message = "";
                    this.form.conditions_group = "AND"
                    this.form.conditions = [];
                    this.$emit("close");
                })
                .catch(() => {
                    this.loading = false;
                    window.Bus.$emit("flash-message", {
                        text: "Error happened when sending customer messages",
                        type: "error",
                    });
                });
        }
    }
}
</script>