<template>
  <div class="collaps-wrapper border-bottom py-1 mb-3">
    <h5
      class="collapse-title d-flex align-items-center justify-content-between"
      data-toggle="collapse"
      data-target="#collapseFilter"
      aria-expanded="false"
      aria-controls="collapseFilter"
      :class="{ active: expandCollapse }"
      type="button"
      @click="expandCollapse = !expandCollapse"
    >
      {{ title }}
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="16" 
        height="16" 
        fill="currentColor" 
        class="bi bi-chevron-down" 
        viewBox="0 0 16 16"
        >
          <path 
            fill-rule="evenodd" 
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />
      </svg>
    </h5>
    <div id="collapseFilter" class="collapse" :class="{ show: expandCollapse }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Collapse",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    expandCollapse: false,
  }),
};
</script>

<style scoped>
.collapse-title.active svg {
  transform: rotate(180deg);
}
</style>
