<template>
  <component :is="layout">
    <div class="d-flex">
      <prompt
        :active="createCustomer"
        :title="'Create a contact'"
        @close="createCustomer = false"
      >
        <div slot="content">
          <CreateCustomer
            @close="createCustomerSuccess"
          />
        </div>
      </prompt>
      <prompt
        :active="sendMessage"
        :title="'Send SMS'"
        @close="sendMessage = false"
      >
      <div slot="content">
        <MessageCustomer
          v-if="sendMessage"
          :conditionData="conditionData"
          :customer-type="type.key"
          @close="sendMessage = false"
        />
      </div>
      </prompt>
      <prompt
        :active="exportModal"
        :title="'Export Customers'"
        @close="exportModal = false"
      >
        <div slot="content">
          <export
            v-if="exportModal"
            :conditionData="conditionData"
            :overview="'customer'"
            :customer-type="type.key"
          />
        </div>
      </prompt>

      <OverviewSidebar
        class="position-fixed p-4 justify-content-between fadeInUp"
        overview="customer"
        search-placeholder="Search for customer"
        @filter="filterCustomers"
        @filterTags="filterTags"
        hide-stores
        hide-views
        hide-status
      />
      <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">

        <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
          <p>
            Using filters you can view a subset of your customers.
          </p>
          <p>
            Sorting is available on most columns. Sort all orders filtered across pages.
          </p>
        </HelpModal>

        <!--<<h3 class="h3 text-center">
          Filters
        </h3>-->
        <ConditionBuilder
          v-if="reRenderConditionBuilder"
          @apply-filters="setConditionData"
          :conditionData="conditionData"
          :model="'customer'"
        />
        <ApiLoader :is-loading="loading">
          <div class="card border-0 shadow-sm mb-4 w-100">
            <div class="card-header">
              <h5>
                Contacts
                <multiselect
                  id="type"
                  v-model="type"
                  name="type"
                  :options="statusList"
                  track-by="key"
                  label="label"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="changeStatus"
                />
                <button
                  @click="sendMessage = true"
                  class="btn btn-primary float-right mr-2"
                >
                  Send SMS
                </button>
                <button class="btn btn-primary float-right mr-2" @click="createCustomer = true">
                  Create Contact
                </button>
                <button
                  class="btn btn-primary float-right mr-2"
                  @click="exportModal = true"
                >
                  Export
                </button>
              </h5>
            </div>
            <div
              v-if="Object.keys(customers.data).length !== 0"
              class="card-body"
            >
              <data-table
                :headers="headers()"
                :sort-url="'/customer'"
                :data="customers.data"
                @sort="setSorting"
              >
                <template #tags="{ item }">
                  <span v-if="item.tags">
                    <Tag
                      v-for="tag in item.tags.slice(0, 2)"
                      :key="tag"
                      :slug="tag"
                    />
                  </span>
                </template>
                <template #actions="{ item }">
                  <router-link
                    :to="{ name: 'contact.single', params: { id: item.id } }"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View contact
                  </router-link>
                </template>
              </data-table>
              <pagination
                :data="customers"
                :limit="3"
                :show-disabled="true"
                align="right"
                @pagination-change-page="getCustomers"
              >
                <span slot="prev-nav" class="text-capitalize">
                  <i class="fas fa-chevron-left mr-2" />
                  Prev
                </span>
                <span slot="next-nav" class="text-capitalize">
                  Next
                  <i class="fas fa-chevron-right ml-2" />
                </span>
              </pagination>
              <h4 class="h6 text-sentence mb-2 mt-7"><span class="dot prospect mr-2"></span> Prospect customers</h4>
            </div>
            <div v-else class="card-body">
              <p class="text-muted">
                This customer list is empty: You're seeing this message either
                because: 1. There are no relevant customers to show for the
                filter 2. The plugin/api is not correctly connected 3.
                Automation.app is (unlikely) down. To resolve this, try new
                filtering options or contact
                <a href="mailto:nerds@automation.app">nerds@automation.app</a>.
              </p>
              <div class="p-4 rounded border-zip text-center">
                <p class="mb-0">
                  No contacts to display!
                </p>
              </div>
            </div>
          </div>
        </ApiLoader>

        <div class="page-help">
          <a @click="openHelp">
            <QuestionCircle />
          </a>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
// Helpers
import {
  loadData,
  updateApi,
  buildPaginationString,
} from "@/helpers/apiConnection";

// Components
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import Tag from "@/components/UI/Tag.vue";
import ConditionBuilder from "@/components/Automation/ConditionBuilder";
import ApiLoader from "@/components/UI/ApiLoader";
import Multiselect from "vue-multiselect";
import customerStatus from "@/helpers/customerStatus";
import Prompt from "@/components/UI/Prompt.vue";
import CreateCustomer from "@/components/UI/CreateCustomer.vue";
import MessageCustomer from "@/Page/Customer/MessageCustomer.vue";
import Export from "@/components/Order/Export.vue";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  name: "CustomerList",
  components: {
    ApiLoader,
    ConditionBuilder,
    Default,
    OverviewSidebar,
    DataTable,
    Tag,
    Multiselect,
    Prompt,
    CreateCustomer,
    MessageCustomer,
    QuestionCircle,
    HelpModal,
    Export
  },
  async beforeRouteEnter(to, from, next) {
    // check if there is a default view first
    const response = await jsonAPI.get("/configuration/view");
    const { data } = response.data;
    const defaultView = data.filter((item) => item.data.view_type === "customer" && item.data.default === true);
    if (defaultView.length > 0) {
      const view = defaultView.pop();
      next((vm) => {
        vm.$router.push({
          name: "contact.view",
          params: {
            id: view.id,
          },
          query: vm.$route.query,
        });
      });
    } else {
      jsonAPI.get("customer?page=1").then((response) => {
        next((vm) => {
          vm.customers = response.data;
        });
      });
    }

  },
  async beforeRouteUpdate(to, from, next) {
    let customers = {};
    if (to.query.sort && to.query.dir) {
      // customers = await loadData("/customer", "1", to.query.sort, to.query.dir);
      if (!Object.prototype.hasOwnProperty.call(to.query, "sort")) {
        next({
          ...to,
          query: {
            ...to.query,
            page: 1,
            sort: to.query.sort,
            dir: to.query.dir,
          },
        });
      } else {
        next();
      }
    } else {
      customers = await loadData("/customer", "1");
    }
    this.customers = customers;
  },
  data() {
    return {
      layout: "Default",
      customers: {
        data: [],
        meta: {},
        links: {},
      },
      activeSearch: "",
      activeFilter: "",
      selectedRange: "",
      activeTag: "",
      page: 1,
      loading: false,
      reRenderConditionBuilder: true,
      conditionData: {},
      type: {
        key: "all",
        label: "All"
      },
      statusList: customerStatus,
      createCustomer: false,
      sendMessage: false,
      exportModal: false,
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters(["getTags"]),
  },
  async mounted() {
    await this.getCustomers(1);
    this.contactStatuses();
  },
  methods: {
    headers() {
      return [
        { title: "Email", key: "email", sortable: true, sortKey: "email" },
        {
          title: "Telephone",
          key: "telephone",
          sortable: true,
          sortKey: "telephone",
        },
        {
          title: "First name",
          key: "first_name",
          sortable: true,
          sortKey: "first_name",
        },
        {
          title: "Last name",
          key: "last_name",
          sortable: true,
          sortKey: "last_name",
        },
        { title: "Tags", key: "tags" },
        { title: "Actions", key: "actions" },
      ];
    },
    setSorting(sorting) {
      const sort = sorting.sortKey;
      const direction = sorting.direction;
      this.$router
        .replace({
          query: {
            sort: sort,
            dir: direction,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getCustomers(this.page);
    },
    getCustomers: async function(page = 1) {
      this.loading = true;
      const contactType = this.type.key;
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      const query = buildPaginationString(sortKey, sortDirection, page, contactType);
      const url = `/customer?${query}`;
      this.customers = await updateApi(url, this.conditionData);
      this.loading = false;
    },
    setSearchQuery(searchQuery) {
      this.activeSearch = searchQuery;
      this.getCustomers(this.page);
    },
    filterCustomers(filter) {
      this.conditionData = {
        conditions: filter.conditions,
        conditionGroup: filter.conditions_group,
        conditionName: filter.name,
      };
      // if no conditions are set, reset the conditionbuilder component
      if (Array.isArray(filter)) {
        if (filter.length === 0) {
          this.reRenderConditionBuilder = false;
          this.$nextTick(() => {
            this.reRenderConditionBuilder = true;
          });
        }
      }
      this.activeFilter = filter.id;
      this.getCustomers(this.page);
    },
    selectCustomerTag(tag) {
      this.activeTag = tag;
      this.getCustomers(this.page);
    },
    resetFilters() {
      this.getCustomers(this.page);
    },
    filterTags(tag_slug) {
      if(tag_slug) {
        const tag_condition = {
          field: "tags",
          field_type: "tag",
          operator: "contains",
          search: "tags",
          type: "customer",
          value: tag_slug
        };
        let conditions = this.conditionData.conditions;
        if(conditions) {
          conditions = conditions.filter(condition => condition.field_type !== "tag");
          if(conditions.length === 0){
            this.conditionData = {
              conditionGroup: "AND",
              conditions: [tag_condition]
            };
          }else {
            conditions.push(tag_condition);
            this.conditionData.conditions = conditions;
          }
        } else {
          this.conditionData = {
            conditionGroup: "AND",
            conditions: [tag_condition]
          };
        }
      } else {
        this.conditionData = {};
        this.reRenderConditionBuilder = false;
        this.$nextTick(() => {
          this.reRenderConditionBuilder = true;
        });
      }
      this.getCustomers(this.page);
    },
    showAllTags(value) {
      if (value.length > 0) {
        const tag_names = [];
        this.getTags.filter((i) => {
          if (value.includes(i.slug)) {
            tag_names.push(i.name);
          }
        });
        return tag_names.join();
      }
    },
    setConditionData(conditionData) {
      this.conditionData = conditionData;
      this.getCustomers();
    },
    contactStatuses() {
      const statusList = this.statusList;
      const all = {
        key: "all",
        label: "All"
      };
      if(!statusList.some(sl => sl.key === 'all')) {
        statusList.unshift(all);
      }
    },
    changeStatus(event) {
      this.type = event;
      this.getCustomers(this.page);
    },
    createCustomerSuccess() {
      this.createCustomer = false;
      this.getCustomers(this.page);
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>

<style scoped>
.multiselect {
  display: inline-block;
  width: 20%
}
.dot {
  height: 10px;
  width: 10px;
  background-color: rgba(137, 243, 129, 0.5) !important;
  border-radius: 50%;
  display: inline-block;
}

.dot.prospect {
  background: rgba(137, 243, 129, 0.5) !important;
  border: 1px solid #c1cce2;
}
</style>
