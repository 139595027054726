<template>
  <span class="badge badge-pill" :class="getType">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "default",
    },
  },
  computed: {
    getType() {
      switch (this.variant.toLowerCase()) {
        case "completed":
          return "badge-success";
        case "processing":
        case "new":
        case "refunded":
          return "badge-secondary";
        case "failed":
        case "canceled":
          return "badge-danger";
        case "pending payment":
        case "on-hold":
        case "authentication required":
          return "badge-warning";
        default:
          return "badge-light";
      }
    },
  },
};
</script>
