<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <div class="media position-relative">
        <div class="media-body">
          <p class="h4 mb-0">
            {{ title }}
          </p>
          <p class="h5 text-muted mb-0" v-html="sentence" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2">
      <button
        v-if="!editing"
        class="btn btn-outline-secondary float-right"
        @click="editing = true"
      >
        Edit
      </button>
      <button
        v-else
        class="btn btn-primary float-right"
        @click="editing = false"
      >
        Close
      </button>
    </div>
    <div v-if="editing" class="col-12 mt-6">
      <div v-if="!simple" class="form-row">
        <div class="form-group col-md-6">
          <label for="given_name">First name</label>
          <input
            id="given_name"
            v-model="address.given_name"
            type="text"
            name="given_name"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-6">
          <label for="family_name">Last name</label>
          <input
            id="family_name"
            v-model="address.family_name"
            type="text"
            name="family_name"
            class="form-control"
          />
        </div>
      </div>
      <div v-if="!simple" class="form-group">
        <label for="organization">Company</label>
        <input
          id="organization"
          v-model="address.organization"
          type="text"
          name="organization"
          class="form-control"
        />
      </div>
      <div class="form-row">
        <div
          class="form-group col-md-6"
          :class="{ 'is-invalid': errors.has('address_line_1') }"
        >
          <label for="address_line_1">Address</label>
          <input
            id="address_line_1"
            v-model="address.address_line_1"
            v-validate="{ required: true }"
            type="text"
            name="address_line_1"
            class="form-control"
            placeholder="1234 Main St"
          />
          <span
            v-show="errors.has('address_line_1')"
            class="invalid-feedback"
            >{{ errors.first("address_line_1") }}</span
          >
        </div>
        <div class="form-group col-md-6">
          <label for="address_line_2">Address 2</label>
          <input
            id="address_line_2"
            v-model="address.address_line_2"
            type="text"
            name="address_line_2"
            class="form-control"
            placeholder="Apartment, studio, or floor"
          />
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-group col-md-4"
          :class="{ 'is-invalid': errors.has('locality') }"
        >
          <label for="locality">City</label>
          <input
            id="locality"
            v-model="address.locality"
            v-validate="{ required: true }"
            type="text"
            name="locality"
            class="form-control"
          />
          <span v-show="errors.has('locality')" class="invalid-feedback">{{
            errors.first("locality")
          }}</span>
        </div>
        <div
          class="form-group col-md-2"
          :class="{ 'is-invalid': errors.has('postal_code') }"
        >
          <label for="postal_code">Zip</label>
          <input
            id="postal_code"
            v-model="address.postal_code"
            v-validate="{ required: true }"
            type="text"
            name="postal_code"
            class="form-control"
          />
          <span v-show="errors.has('postal_code')" class="invalid-feedback">{{
            errors.first("postal_code")
          }}</span>
        </div>
        <div
          class="form-group col-6"
          :class="{ 'is-invalid': errors.has('country') }"
        >
          <label for="country" class="control-label text-capitalize"
            >Country</label
          >
          <multiselect
            id="country"
            v-model="address.country"
            v-validate="{ required: true }"
            name="country"
            :options="countries"
            label="name"
            track-by="code"
            :show-labels="false"
          />
          <span v-show="errors.has('country')" class="invalid-feedback">{{
            errors.first("country")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import addressFormatter from "@fragaria/address-formatter";
import countries from "@/helpers/countries";

export default {
  name: "EditableAddress",
  components: { Multiselect },
  inject: ["$validator"],
  props: {
    title: {
      type: String,
      default: "Address",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default: () => ({
        given_name: null,
        family_name: null,
        organization: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        locality: null,
        dependent_locality: null,
        administrative_area: null,
        country: null,
      }),
    },
    defaultCountry: {
      type: Object,
      default: () => ({
        code: "",
        name: "",
      }),
    },
  },
  data: function() {
    return {
      editing: false,
      editable_address: {},
      countries: countries,
    };
  },
  computed: {
    sentence() {
      if (this.address.address_line_1) {
        const countryName =
          this.address.country && this.address.country.name
            ? this.address.country.name
            : this.defaultCountry.name;
        const formatted = addressFormatter.format(
          {
            road: this.address.address_line_1,
            city: this.address.locality,
            postcode: this.address.postal_code,
            county: this.address.dependent_locality,
            state: this.address.administrative_area,
            country: countryName,
            countryCode: this.address.country_code,
          },
          { output: "array" }
        );
        return formatted.join("<br/>");
      }
      return "Not set";
    },
  },
  mounted() {
    this.editable_address = this.address;
  },
};
</script>
