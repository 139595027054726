<template>
  <div class="text-center">
    <div v-if="showLine" class="css-12w0090" />
    <div
      v-if="show"
      v-tooltip="'Add a condition'"
      class="detail-step add-step-buttons"
      @click="click"
    >
      <div class="css-1ngft43">
        <span
          class="css-174qvk7"
          data-title="Click to set conditions to run your automation"
          data-intro=""
          data-step="3"
        >
          <svg viewBox="0 0 512 512">
            <path
              d="M318 194V91c0-35-28-63-62-63-35 0-62 28-62 63v103H91c-35 0-63 28-63 62 0 35 28 62 63 62h103v103c0 35 28 63 62 63 35 0 62-28 62-63V318h103c35 0 63-28 63-62 0-35-28-62-63-62z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionBtn",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click: function() {
      this.$emit("newCondition");
    },
  },
};
</script>
